import { useStorage } from '@vueuse/core';

const KEY = 'friendRequestCount';

interface IFriendRequestCount {
	count: number | null
	lastUpdate: number | null
}

export const useFriendCountSession = () => useStorage<IFriendRequestCount>(KEY, { count: null, lastUpdate: null }, sessionStorage);
